<style scoped>
    :deep(.article-container) {
        display: flex;
        justify-content: space-between;
        max-width: 15vw;
    }

    :deep(#articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2) {
        flex: 1;
    }

    .form-column .articles-group-by-family:not(:last-child) {
        margin-bottom: 25px;
    }

    :deep(.list-with-delete) {
        max-height: unset !important;
    }
</style>

<template>
    <ButtonTopbar buttonText="Créer un type de nomenclature" @onTopbarButtonClicked="displayCreateNomenclatureTypeModal">
        <template v-slot:iconBefore>
            <font-awesome-icon :icon="['fas', 'plus']"/>
        </template>
    </ButtonTopbar>
    <Aggrid
            apiRoute="nomenclatureType/list"
            :apiParams="filter"
            :columnDefs="columnDefs"
            :context="context"
            :frameworkComponents="frameworkComponents"
            aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <div>
                <BaseInput v-model="search" label="Rechercher un type" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']"/>
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditOrCopyNomenclatureType === true" :fullscreen="true">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', createOrEditOrCopyMode === 'create' || createOrEditOrCopyMode === 'copy' ? 'plus' : 'edit']"/>
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrEditOrCopyMode === 'create' || createOrEditOrCopyMode === 'copy'">CRÉER UN TYPE DE NOMENCLATURE</div>
            <div v-show="createOrEditOrCopyMode === 'edit'">MODIFIER UN TYPE DE NOMENCLATURE</div>
        </template>
        <template v-slot:modalContent>
            <form class="form-column">
                <div>
                    <BaseSelect
                            v-model="nomenclatureType.spindleBrand"
                            label="Marque de broche"
                            name="searchSpindleBrand"
                            api="spindleBrand/search"
                            fieldValue="id"
                            fieldLabel="name"
                            :required="true"
                            :error="formErrors?.searchSpindleBrand?.error?.message"
                            @onChange="onFormInputChange"
                            :displayError="displayError"
                            :searchable="true"
                    />
                    <BaseSelect
                            v-model="nomenclatureType.spindleType"
                            label="Type de broche"
                            name="searchSpindleType"
                            :minChars="1"
                            api="spindleType/search"
                            :apiParams="{
                            spindleBrandId : nomenclatureType.spindleBrand?.value
                        }"
                            fieldValue="id"
                            fieldLabel="name"
                            :required="true"
                            :error="formErrors?.searchSpindleType?.error?.message"
                            @onChange="onFormInputChange"
                            :displayError="displayError"
                            :searchable="true"
                    />
                    <BaseInput
                            v-model="nomenclatureType.documentLink"
                            label="Lien vers les documents"
                            type="text"
                            name="documentLink"
                            validator="text"
                            :error="formErrors?.documentLink?.error?.message"
                            @onChange="onFormInputChange"
                            :displayError="displayError"
                            :disabled="true"
                    />
                    <BaseInput
                            v-model="nomenclatureType.technicalDocumentationLink"
                            label="Lien vers la doc. technique"
                            type="text"
                            name="technicalDocumentationLink"
                            validator="text"
                            :error="formErrors?.technicalDocumentationLink?.error?.message"
                            @onChange="onFormInputChange"
                            :displayError="displayError"
                            :disabled="true"
                    />
                </div>
                <div>
                    <BaseSelect
                            v-model="form.disciplineCurrent"
                            label="Ajouter une discipline"
                            @onChange="onDisciplineAdd"
                            api="discipline/search"
                            fieldValue="id"
                            fieldLabel="label"
                            :searchable="true"
                            :minChars="1"
                    />
                    <ListWithDelete v-model="nomenclatureType.disciplines" label="Liste des disciplines">
                        <template v-slot:content="{ item }">
                            <div>{{ item.label }}</div>
                        </template>
                    </ListWithDelete>
                </div>
                <div>
                    <BaseSelect
                      v-model="form.articleCurrentEstablishmentFilter"
                      label="Etablissement"
                      :defaultOptions="establishments"
                      name="searchArticleEstablishment"
                      :display-tag="true"
                      fieldLabel="label"
                      fieldValue="id"
                    />
                    <BaseSelect
                        v-model="form.articleCurrent"
                        label="Ajouter un article"
                        @onChange="onArticleAdd"
                        api="article/search"
                        :apiParams ="{
                          establishment: form.articleCurrentEstablishmentFilter?.value
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        :searchable="true"
                    />
                    <div v-for="(groupByFamily, index) in nomenclatureType.articlesGroupByFamily" class="articles-group-by-family" :key="index">
                        <ListWithDelete v-model="groupByFamily.articles" :label="groupByFamily.family" v-if="groupByFamily.articles.length > 0">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                            v-model="item.quantity"
                                            type="text"
                                            :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                            :name="'quantity_' + index"
                                            validator="decimal_2"
                                            :error="formErrors['quantity_' + index]?.error?.message"
                                            @onChange="onFormInputChange"
                                            :displayError="displayError"
                                            :isSmall="true"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModal" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']"/>
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']"/>
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
  import ButtonTopbar from '../../components/Topbar/ButtonTopbar'
  import BaseButton from '../../components/Base/BaseButton'
  import BaseInput from '../../components/Base/BaseInput'
  import BaseSelect from '../../components/Base/BaseSelect'
  import BaseRadioButton from '../../components/Base/BaseRadioButton'
  import Aggrid from '../../components/Aggrid/Aggrid'
  import Modal from '../../components/App/Modal'
  import RadioButtonContainer from '../../components/App/RadioButtonContainer'
  import ListWithDelete from '../../components/App/ListWithDelete'
  import ListNomenclatureTypesActionButtonsRenderer from './ListNomenclatureTypesActionButtonsRenderer.vue'
  import changeTypeEnum from '../../enums/changeTypeEnum'
  import axios from 'axios'
  import config from "../../config"
  import {DROPBOX_REFRESH_ACCESS_TOKEN} from "@/store/actions/dropboxToken";

  export default {
    name: 'NomenclatureTypes',
    components: {
      ButtonTopbar,
      BaseButton,
      BaseInput,
      BaseSelect,
      BaseRadioButton,
      RadioButtonContainer,
      ListWithDelete,
      ListNomenclatureTypesActionButtonsRenderer,
      Aggrid,
      Modal
    },
    data() {
      return {
        columnDefs: [
          {field: 'spindleType.spindleBrand.name', headerName: `Marque de la broche`, flex: 1, lockPosition: true, suppressMenu: true},
          {field: 'spindleType.name', headerName: `Type de broche`, flex: 1, lockPosition: true, suppressMenu: true},
          {headerName: '', width: 220, cellRenderer: 'ListNomenclatureTypesActionButtonsRenderer', suppressMenu: true}
        ],
        frameworkComponents: {
          ListNomenclatureTypesActionButtonsRenderer: ListNomenclatureTypesActionButtonsRenderer
        },
        context: null,
        displayModalCreateOrEditOrCopyNomenclatureType: false,
        createOrEditOrCopyMode: null,
        search: '',
        searchTimeout: null,
        nomenclatureType: null,
        form: {
          disciplineCurrent: null,
          articleCurrent: null,
          articleCurrentEstablishmentFilter: null
        },
        filter: {
          deleted: false,
          search: ''
        },
        displayError: false,
        formErrors: {},
        establishments: [
            { label: 'EBC', value: 'ebc' },
            { label: 'SMG', value: 'smg' }
        ],
      }
    },
    beforeMount() {
      this.context = {
        componentParent: this
      };


      this.resetNomenclatureType();
    },
    mounted() {


      if (this.$route.query?.search) {
        this.loadNomenclatureType();
      }
    },
    methods: {
      closeModal() {
        this.resetNomenclatureType();
        this.displayModalCreateOrEditOrCopyNomenclatureType = false;
        this.displayError = false;
      },
      submitModal() {
        if (this.createOrEditOrCopyMode === 'create' || this.createOrEditOrCopyMode === 'copy') {
          this.createNomenclatureType();
        } else if (this.createOrEditOrCopyMode === 'edit') {
          this.editNomenclatureType();
        }
      },
      displayCreateNomenclatureTypeModal() {
        this.createOrEditOrCopyMode = 'create';
        this.resetNomenclatureType();

        axios.get(`discipline/listMilestoneDisciplines`, {
            toastError: true,
            showPreloader: true
        })
        .then((response) => {
            this.nomenclatureType.disciplines = response.data.map(discipline => {
                return {
                    value: discipline.id,
                    label: discipline.label
                };
            })
            
            axios.get(`article/listMilestoneArticles`, {
                toastError: true,
                showPreloader: true
            })
            .then((response) => {
                let articles = response.data;

                this.nomenclatureType.articles = [];
                this.nomenclatureType.articlesGroupByFamily = [];

                for (let article of articles) {
                    article.quantity = 1;
                    
                    this.nomenclatureType.articles.push({
                        value: article.id,
                        label: article.label,
                        quantity: article.quantity,
                        unit: article.unit,
                        data: article
                    });

                    let articleFamilyIndex = this.nomenclatureType.articlesGroupByFamily.findIndex(articleFamily => articleFamily.family === article.articleFamily?.label);

                    if (articleFamilyIndex !== -1) {
                        this.nomenclatureType.articlesGroupByFamily[articleFamilyIndex].articles.push(article);
                    } else {
                        this.nomenclatureType.articlesGroupByFamily.push({
                            family: article.articleFamily?.label,
                            articles: [article]
                        });
                    }
                }

                this.displayModalCreateOrEditOrCopyNomenclatureType = true;
            })
            .catch(() => {});
        })
        .catch(() => {});
      },
      displayEditNomenclatureTypeModal(nomenclatureType) {
        this.createOrEditOrCopyMode = 'edit';
        this.prepareEditOrCopyNomenclatureType(nomenclatureType);
        this.displayModalCreateOrEditOrCopyNomenclatureType = true;
      },
      displayCopyNomenclatureTypeModal(nomenclatureType) {
        this.createOrEditOrCopyMode = 'copy';
        this.prepareEditOrCopyNomenclatureType(nomenclatureType);
        this.displayModalCreateOrEditOrCopyNomenclatureType = true;
      },
      openDocumentLink(nomenclatureType) {
        window.open(nomenclatureType.documentLink, '_blank').focus();
      },
      openTechnicalDocumentationLink(nomenclatureType) {
        openDocumentLink(nomenclatureType);
      },
      prepareEditOrCopyNomenclatureType(nomenclatureType) {
        this.nomenclatureType = JSON.parse(JSON.stringify(nomenclatureType));
        this.nomenclatureType.spindleBrand = {
          value: nomenclatureType.spindleType.spindleBrand.id,
          label: nomenclatureType.spindleType.spindleBrand.name,
        };
        this.nomenclatureType.spindleType = {
          value: nomenclatureType.spindleType.id,
          label: nomenclatureType.spindleType.name,
        };
        this.nomenclatureType.disciplines = this.nomenclatureType.disciplines.map((discipline) => {
          return {
            value: discipline.id,
            label: discipline.label
          }
        });
        this.nomenclatureType.articles = this.nomenclatureType.articles.map((article) => {
          return {
            value: article.id,
            label: article.label,
            quantity: article.quantity,
            unit: article.unit
          }
        });
      },
      resetNomenclatureType() {
        this.nomenclatureType = {
          documentLink: null,
          technicalDocumentationLink: null,
          spindleBrand: null,
          spindleType: null,
          articles: [],
          disciplines: [],
          articlesGroupByFamily: []
        };
      },
      loadNomenclatureType() {
        axios.get(`nomenclatureType/${this.$route.query?.search}`)
          .then((response) => {
            this.displayEditNomenclatureTypeModal(response.data);
          })
          .catch((err) => {
            console.warn(err);
          });
      },
      formateNomenclatureType(nomenclatureType) {
        let tmpArticles = [];

        nomenclatureType = JSON.parse(JSON.stringify(nomenclatureType));
        nomenclatureType.articlesGroupByFamily.map((articleFamily) => {
          articleFamily.articles.map((anArticle) => {
            tmpArticles.push({
              id: anArticle.id,
              quantity: anArticle.quantity
            });
          })
        });
        nomenclatureType.articles = tmpArticles;
        nomenclatureType.disciplines = nomenclatureType.disciplines.map((discipline) => discipline.value);
        nomenclatureType.spindleType = nomenclatureType.spindleType.value;
        delete nomenclatureType.spindleBrand;
        delete nomenclatureType.documentLink;
        delete nomenclatureType.technicalDocumentationLink;
        return nomenclatureType;
      },
      createNomenclatureType() {
        if (!this.checkForm()) return;

        axios
          .post('nomenclatureType/create', this.formateNomenclatureType(this.nomenclatureType), {
            toastSuccessMessage: `Type de nomenclature créé`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeModal();
            this.emitter.emit('ag-grid-reload')
          })
          .catch(() => {
          });
      },
      editNomenclatureType() {
        if (!this.checkForm()) return;

        axios
          .put('nomenclatureType/update', this.formateNomenclatureType(this.nomenclatureType), {
            toastSuccessMessage: `Modification effectuée`,
            toastError: true,
            showPreloader: true
          })
          .then(() => {
            this.closeModal();
            this.emitter.emit('ag-grid-reload')
          })
          .catch(() => {
          });
      },
      onFormInputChange(input) {
        if (input.error?.message !== null) {
          this.formErrors[input.name] = input;
        } else {
          delete this.formErrors[input.name];
        }

        switch (input.name) {
          case 'searchSpindleBrand':
            if (input.changeType === changeTypeEnum.USER) this.nomenclatureType.spindleType = null;
          case 'searchSpindleType':
            if (this.nomenclatureType.spindleBrand != null && this.nomenclatureType.spindleType != null) {
              let baseLink = `${config.DROPBOX_URL}/${this.getPathNameFromLabel(this.nomenclatureType.spindleBrand.label)}/${this.getPathNameFromLabel(this.nomenclatureType.spindleType.label)}`;
              this.nomenclatureType.documentLink = `${baseLink}/documents`;
              this.nomenclatureType.technicalDocumentationLink = `${baseLink}/documentations`;
            }
            if (this.nomenclatureType.spindleBrand === null && this.nomenclatureType.spindleType != null) {
              this.nomenclatureType.spindleBrand = {
                value: input.value.data.spindleBrand.id,
                label: input.value.data.spindleBrand.name
              }
            }
            break;
        }
      },
      getPathNameFromLabel(label) {
        return label.toLowerCase().trim().replace(/ /g, '_');
      },
      checkForm() {
        this.displayError = true;

        return Object.keys(this.formErrors).length === 0;
      },
      onSearchInputChange(input) {
        if (this.searchTimeout !== null) {
          clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
          this.filter.search = input.value
        }, 250)
      },
      onDisciplineAdd(discipline) {
        this.$nextTick(() => {
          this.form.disciplineCurrent = null;
        });

        // On ajoute la discipline si elle n'est pas déjà présente
        if (discipline.value != null && this.nomenclatureType.disciplines.filter(element => element.value === discipline.value.value).length === 0) {
          this.nomenclatureType.disciplines.push(discipline.value);
        }
      },
      onArticleAdd(article) {
        this.$nextTick(() => {
          this.form.articleCurrent = null;
          this.form.articleCurrentEstablishmentFilter = null;
        });

        let isArticleNotPresent = article.value != null && this.nomenclatureType.articles.filter(element => element.value === article.value.value).length === 0;

        // On ajoute l'article s'il n'est pas déjà présent
        if (isArticleNotPresent) {
          this.nomenclatureType.articles.push({
            ...article.value,
            quantity: 1,
            unit: article.value.data.unit
          });

        // On ajoute également l'article dans la famille d'article appropriée
          let findArticleFamily = false;
          this.nomenclatureType?.articlesGroupByFamily?.map(articleFamily => {
            if (articleFamily.family === article.value?.data?.articleFamily?.label) {
              articleFamily.articles.push(article.value.data);
              findArticleFamily = true;
            }
          });

          // Si on ne trouve pas la famille d'article, on la créée
          let tmpTab = [];
          tmpTab.push(article.value?.data);

          if (!findArticleFamily) {
            this.nomenclatureType?.articlesGroupByFamily?.push({
              family: article.value?.data?.articleFamily?.label,
              articles: tmpTab
            })
          }
        }

      }
    }
  }
</script>